<template>
  <v-container fluid class="pa-0">
    <portal to="appBarContentLeft">
      <v-row v-if="org" class="flex-nowrap" align="center" justify="start" no-gutters>
        <span class="text-no-wrap font-weight-light text-h5 mr-4">{{ org.name }}</span>
      </v-row>
    </portal>
    <Conversations :height="conversationHeight"></Conversations>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Conversations from '../components/Conversations.vue'

const data = {
  conversationHeight: 0,
}

function handleWindowResize() {
  const headerHeight = document.querySelector('header.v-app-bar').offsetHeight
  clearTimeout(this.resizeTimer)
  this.resizeTimer = setTimeout(() => {
    this.conversationHeight = window.innerHeight - headerHeight
  }, 300)
}

async function mounted() {
  this.handleWindowResize()
  window.addEventListener('resize', this.handleWindowResize)
}

async function destroyed() {
  window.removeEventListener('resize', this.handleWindowResize)
}

export default {
  components: {
    Conversations,
  },
  data: () => Object.assign({}, data),
  computed: {
    ...mapState('org', ['org']),
  },
  methods: {
    handleWindowResize,
  },
  mounted,
  destroyed,
}
</script>
